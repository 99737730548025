<template>
  <FocusTrap>
    <div class="card">
    <div class="card-header" style="background-color: #1b5e20;color: white;padding: 5px;">
      <h3 class="card-title">Doctor Visit Updates</h3>
      <h4 class="font-weight-semibold">{{reporting.doctor.name}}</h4>
    </div>
    <div class="card-body">
      <div class="table-responsive table-scrollable">
        <table class="table" >
          <thead>
          <th style="width: 40px;">#</th>
          <th>Product</th>
<!--          <th style="width: 50px;">Qty</th>-->
          <th style="width: 50px;">#</th>
          </thead>
          <tbody style="height: 750px">
          <tr v-for="(item,idx) in reporting.promoted_products" style="padding: 0px;">
            <td style="padding: 0px;"><input name="cbProduct" type="checkbox" class="form-control" :ref="`r_${item.id}`"  :id="item.id" :value="item.id" v-model="item.selected"></td>
            <td style="padding: 0px;">{{item.name}}</td>
<!--            <td style="padding: 0px;"><input  :id="`txt${item.id}`" :name="`${item.id}`" class="form-control" style="width: 50px;padding: 0px;" type="number" min="0" @change="item_qty_changed" v-model="item.qty"></td>-->
            <td style="padding: 0px;"> <button class="btn btn-outline" @click="report_breakup(`${ JSON.stringify(item)}`)">Info</button> </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer text-right" style="padding: 10px;" >
      <button class="btn btn-outline-success" @click="yes_update_clicked">Update</button>
    </div>

      <FocusTrap>
        <vmodal name="breakup_window" transition="nice-modal-fade" :delay="100" :resizable="true"  height="520" >
          <ReportingProductInfo :product="selected_product" @reporting_product_info_saved="breakup_saved" ></ReportingProductInfo>
        </vmodal>
    </FocusTrap>

  </div>
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import {store} from '@/store/store.js'
import ReportingProductInfo from '../views/mms/reporting/ReportingProductInfo'

export default {
  name: 'DoctorVisitYes',
  components: { ReportingProductInfo },
  store,
  props:{
    reporting : {
      type :Object,
      default:() => JSON.parse('{"id":0,"work_date":"","doctor":{"id":0,"name":"Doctor Name","hospital":"","sex":"M","address":"","area_id":1,"qualification":"qualification","speciality":"","class":"","dob":"1900-01-01","dow":"","no_of_visits":0,"last_visit":"2000-01-01","mobile":"","email":"","web":"","whatsapp":"","spouse_name":"","spouse_dob":"","spouse_profession":"","data":"","area":{"id":1,"name":""},"categories":[]},"visit_time":"","visited":false,"not_met_type":"","not_met_remarks":"","lbl_reminder":false,"sample_reminder":false,"cmc_reminder":false,"promoted_products":[]}')
    },
    products :{
      type : Array,
      default:() => []
    },
    selected_product:{"id":0,"name":""},
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
    // this.reporting =  JSON.parse(JSON.stringify(this.reporting));
  },
  mounted () {
    // alert(this.$props.reporting.doctor.name);
  },
  methods:{
    item_qty_changed(){
    },
    yes_update_clicked(){
      const self = this;
      try{
        // self.get_selected_products();
        self.$props.reporting.doctor_id = self.$props.reporting.doctor.id;
        self.$props.reporting.fieldpeople_id = self.$store.state.user.id;
        // self.$data.reporting.jointworker_id = resp.data.jointworker_id;
        self.$props.reporting.work_date =  self.$store.state.user.working_date;
        self.$props.reporting.visited =  true;
        self.$props.reporting.visit_time =  dateInYyyyMmDdHhMmSs(new Date());
        self.$emit('doctor_visit_yes_updated');
      }catch (e) {
        alert(e);
      }
    },
    get_selected_products(){
      const self = this;
      try {

        let checkboxes = document.getElementsByName('cbProduct');
        let result = "";
        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].checked) {
            result += checkboxes[i].value + ", ";
          }
        }

        // self.$props.products.forEach((product) =>{
        //   self.$props.reporting.promoted_products.push(product);
        // });
      }catch (e) {
        alert(e);
      }
    },
    report_breakup(item){
      this.selected_product = JSON.parse(item);
      this.$modal.show('breakup_window');
    },
    breakup_saved(product_info){
      const self = this;
      self.$modal.hide('breakup_window');
      self.$props.reporting.promoted_products.push(product_info);
      console.log(JSON.stringify(self.$props.reporting));
    },
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
